import { useState } from 'react';
import { useAnimateVisibility } from 'ui-components';
import { getColumnElement } from '../common/helpers';
import { NavigationItem } from '../types';
import { MobileMainNavigation } from './MobileMainNavigation';
import { MobileSubNavigation } from './MobileSubNavigation';

type Props = {
  items: NavigationItem[];
  languages: NavigationItem;
  currencies: NavigationItem;
  goBackMicrocopy: string;
};

const MobileMenu = ({
  items,
  languages,
  currencies,
  goBackMicrocopy,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState<NavigationItem | null>(null);
  const mainMenuAnimationStates = useAnimateVisibility(!Boolean(selectedItem));
  const subMenuAnimationStates = useAnimateVisibility(Boolean(selectedItem));

  return (
    <div className="main-grid h-full overflow-auto pb-14">
      <nav className="col-span-4 col-start-1 md:col-span-8 md:col-start-3">
        {selectedItem ? (
          <MobileSubNavigation
            heading={selectedItem.mainLink?.value}
            goBackMicrocopy={goBackMicrocopy}
            goBack={() => setSelectedItem(null)}
            animationStates={subMenuAnimationStates}
          >
            {selectedItem.columns.map((column, index) =>
              getColumnElement(column, index, selectedItem.columns)
            )}
          </MobileSubNavigation>
        ) : (
          <MobileMainNavigation
            items={items}
            languages={languages}
            currencies={currencies}
            animationStates={mainMenuAnimationStates}
            handleItemSelect={setSelectedItem}
          />
        )}
      </nav>
    </div>
  );
};

export default MobileMenu;
