import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BenefitBarDataFragment } from '@graphql/generated-contentful/graphql';

/**
 * Transforms a BenefitBarDataFragment from the CMS to a simplified object structure
 * that can be passed to the BenefitsBar UI library component.
 */
export const transformBenefitsBar = (
  data: BenefitBarDataFragment
): { text: string; icon: string }[] | undefined => {
  return data.itemsCollection?.items.map((item) => ({
    text: documentToHtmlString(item?.text?.json),
    icon: item?.icon || '',
  }));
};
