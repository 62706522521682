import { useCurrency } from '@contexts/CurrencyContext';
import { CurrencyLink } from '../types';
import { HeaderMenuListItem } from './HeaderMenuListItem';

type Props = {
  items: CurrencyLink[];
};
export const NavigationCurrencyLinksColumn = ({ items }: Props) => {
  const { setCurrency } = useCurrency();

  const handleCurrencyChange = (currencyCode: string) => {
    setCurrency(currencyCode);
  };

  return (
    <ul className="text-white">
      {items.map((currency) => (
        <li key={currency.title} className="mb-4">
          <HeaderMenuListItem
            hasChildren={false}
            value={currency.title}
            handleSelect={() => handleCurrencyChange(currency.currencyCode)}
          />
        </li>
      ))}
    </ul>
  );
};
