/**
 * Returns the root that loads in fonts to the next app.
 * Useful for loading in React portals, since if they load in at the document.body, they will miss being wrapped by any
 * context + font classes that wrap the page.
 *
 * @returns {HTMLElement} The node that wraps the entire page content.
 */
export const getPageRootNode = () => {
  return typeof document !== 'undefined'
    ? document.getElementById('page-root')
    : null;
};
