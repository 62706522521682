import {
  FooterMenuList,
  LanguageLink,
} from '@graphql/generated-contentful/graphql';
import { Maybe } from '@lib/types/Maybe';
import { getPageRootNode } from '@lib/util/getPageRootNode';
import { List } from 'phosphor-icons';
import { useState } from 'react';
import { FullscreenModal } from 'ui-components';
import { IconMenuOption } from '../../IconMenuOption';
import { NavigationItem } from '../types';
import MobileMenu from './MobileMenu';

type Props = {
  navigationItems: NavigationItem[];
  currencyAriaLabel?: string;
  microcopy: Record<string, string>;
  languageMenu: Maybe<FooterMenuList>;
};

export const MobileNavigationMenuModal = ({
  navigationItems,
  currencyAriaLabel = 'Currency',
  microcopy,
  languageMenu,
}: Props) => {
  const pageRootNode = getPageRootNode();
  const [isMobileNavMenuActive, setIsMobileNavMenuActive] = useState(false);

  const handleToggleMobileNavMenu = () => {
    setIsMobileNavMenuActive((prevMenuActive) => !prevMenuActive);
  };

  return (
    <>
      <IconMenuOption
        name={
          isMobileNavMenuActive
            ? microcopy['layout.close-menu'] || 'Close menu'
            : microcopy['layout.open-menu'] || 'Open menu'
        }
        onClick={handleToggleMobileNavMenu}
      >
        <List size={20} />
      </IconMenuOption>
      <FullscreenModal
        returnFocus
        isActive={isMobileNavMenuActive}
        closeLabel={microcopy['layout.close-menu'] || 'Close menu'}
        closeModal={handleToggleMobileNavMenu}
        portalDomNode={pageRootNode}
        className="lg:hidden"
      >
        <MobileMenu
          goBackMicrocopy={
            microcopy['layout.go-back-to-navigation'] ?? 'Go back to navigation'
          }
          items={navigationItems}
          languages={{
            mainLink: {
              value: microcopy['layout.languages'],
              href: '',
            },
            columns: [
              {
                items: languageMenu?.menuItemsCollection
                  ?.items as LanguageLink[],
              },
            ],
          }}
          currencies={{
            mainLink: { value: currencyAriaLabel, href: '' },
            columns: [
              {
                items: [
                  { title: 'EUR', currencyCode: 'EUR' },
                  { title: 'USD', currencyCode: 'USD' },
                ],
              },
            ],
          }}
        />
      </FullscreenModal>
    </>
  );
};
