/** @type {import('tailwindcss').Config} */
const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  darkMode: 'class', // or 'media'
  theme: {
    screens: {
      md: '768px',
      lg: '1024px',
      xl: '1240px',
      '2xl': '1536px',
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#000000',
      white: '#FFFFFF',
      success: {
        DEFAULT: '#3AC561',
        700: '#007f52',
      },
      error: '#E12727',
      green: {
        300: '#ddeae5',
        500: '#007f52',
      },
      orange: {
        DEFAULT: '#E57C00',
      },
      blue: {
        100: '#E9F5FE',
        200: '#D3EAFD',
        300: '#BDE0FC',
        400: '#A6D5FA',
        500: '#90CAF9',
        600: '#7AC0F8',
        700: '#64B6F7',
        DEFAULT: '#2196F3',
        800: '#4DABF5',
        900: '#37A0F4',
        dark: '#0A5CF5',
      },
      grey: {
        100: '#F5F5F5',
        200: '#E6E6E6',
        300: '#CCCCCC',
        400: '#A6A6A6',
        500: '#808080',
        600: '#666666',
        700: '#4C4C4C',
        800: '#262626',
        900: '#141414',
      },
      pink: {
        200: '#fce6e6',
      },
      gold: {
        DEFAULT: '#ff9800',
      },
      red: {
        200: '#FBEAEA',
        500: '#E12727',
        600: '#D3171E',
      },
      overlay: {
        DEFAULT: 'rgba(0,0,0,0.5)',
      },
    },
    container: {
      center: true,
      padding: {
        DEFAULT: `1rem`,
        md: '2rem',
        lg: '2rem',
        xl: '0rem',
      },
    },
    fontFamily: {
      sans: ['Inter', ...defaultTheme.fontFamily.sans],
    },
    fontSize: {
      xxs: ['0.65rem', { lineHeight: '0.75rem' }],
      xs: ['0.75rem', { lineHeight: '1.25rem' }],
      sm: ['0.875rem', { lineHeight: '1.25rem' }],
      base: ['1rem', { lineHeight: '1.375rem' }],
      md: ['1.125rem', { lineHeight: '1.5rem' }],
      lg: ['1.25rem', { lineHeight: '1.6rem' }],
      xl: ['1.5rem', { lineHeight: '2rem' }],
      '2xl': ['1.6rem', { lineHeight: '2rem' }],
      '3xl': ['1.75rem', { lineHeight: '2rem' }],
      '4xl': ['2rem', { lineHeight: '2.25rem' }],
      '5xl': ['2.125rem', { lineHeight: '2.375rem' }],
      '6xl': ['2.25rem', { lineHeight: '2.5rem' }],
      '7xl': ['2.75rem', { lineHeight: '3rem' }],
    },
    letterSpacing: {
      tighter: '-0.006em',
      tight: '-0.003em',
      normal: '0em',
      medium: '0.14em',
      wide: '0.18em',
      wider: '0.2em',
    },
    extend: {
      aspectRatio: {
        '3/2': '3 / 2',
      },
      backgroundImage: {
        'outline-btn-hover':
          'linear-gradient(to right, #0A5CF5 50%, transparent 50%)',
        'outline-btn-hover-dark':
          'linear-gradient(to right, #FFFFFF 50%, transparent 50%)',
        'outline-btn-hover-light':
          'linear-gradient(to right, #000000 50%, transparent 50%)',
      },
      backgroundSize: {
        '200-100': 'calc(200% + 4px) 100%',
      },
      lineHeight: {
        7: '1.725rem',
      },
      spacing: {
        18: '4.375rem',
        110: '25rem',
        112: '28rem',
        116: '32rem',
      },
      transitionDuration: {
        120: '120ms',
      },
      transitionProperty: {
        color: 'color',
        margin: 'margin',
      },
      boxShadow: {
        card: '0px 8px 60px -8px rgba(0,0,0,0.16);',
        cardDark: '0px 12px 44px 0px rgba(33,150,243,0.4);',
      },
      scale: {
        143: '1.43',
      },
      maxWidth: {
        'search-bar': '800px',
        'search-xl': '1292px',
        'search-2xl': '1732px',
      },
    },
  },
  plugins: [
    function ({ addVariant }) {
      addVariant('children', '& > *');
    },
    function ({ addComponents }) {
      addComponents({
        // We want container to be fluid at all screen widths except xl
        // need to override since by default container's max width is set to width of screen breakpoint
        '.container': {
          maxWidth: '100%',
          '@screen xl': {
            maxWidth: '1230px',
          },
        },
      });
    },
  ],
};
