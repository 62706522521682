import { LanguageLink } from '@graphql/generated-contentful/graphql';
import { CTA } from '@lib/types/common/CTA';
import { Link } from '@lib/types/common/Link';

export type NavigationItem = {
  mainLink: Link;
  isRightAligned?: boolean;
  columns: NavigationColumn[];
};

export type NavigationColumn =
  | NavigationLinks
  | NavigationImage
  | CTA
  | NavigationLanguageLinks
  | NavigationCurrencyLinks;

export type NavigationLinks = {
  title?: string;
  highlightFirstLink?: boolean;
  items: Link[];
};

export type NavigationImage = {
  src: string;
  alt?: string;
  description?: string;
  link: Link;
};

export type NavigationLanguageLinks = {
  title?: string;
  items: LanguageLink[];
};

export type NavigationCurrencyLinks = {
  title?: string;
  items: CurrencyLink[];
};

export type CurrencyLink = {
  title: string;
  currencyCode: string;
};

// Maps the Contentful Navigation items to our generic Navigation types
export enum ContentfulType {
  Navigation = 'Navigation',
  NavigationSubsection = 'NavigationSubsection',
  NavigationImage = 'NavigationImage',
  Cta = 'Cta',
}
