import c from 'classnames';
import {
  HeaderMenuListItem,
  HeaderMenuListItemProps,
} from '../Navigation/common/HeaderMenuListItem';
import { UserMenuSectionDisplay } from './types';

type UserMenuSectionProps = {
  display?: UserMenuSectionDisplay;
  items: HeaderMenuListItemProps[];
};

const USER_MENU_ITEM_TEST_ID = 'user-menu-item';

export const UserMenuSection = ({
  display = 'All',
  items,
}: UserMenuSectionProps) => {
  return items.map((item, index) => {
    if (!item.href && !item.handleSelect) {
      return null;
    }

    const listItemClassNames = c('pb-4', {
      'pt-5': index === 0,
      'border-b border-grey-800 pb-5 last-of-type:border-0':
        index === items.length - 1,
      'lg:hidden': display === 'Mobile',
      'hidden lg:block': display === 'Desktop',
    });

    return (
      <li key={item.value} className={listItemClassNames}>
        <HeaderMenuListItem
          {...item}
          testId={USER_MENU_ITEM_TEST_ID}
          classNames="lg:pl-4"
        />
      </li>
    );
  });
};
