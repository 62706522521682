import { LanguageLink } from '@graphql/generated-contentful/graphql';
import { useChangeLocale } from '@lib/hooks/useChangeLocale';
import { HeaderMenuListItem } from './HeaderMenuListItem';

type Props = {
  items: LanguageLink[];
};
export const NavigationLanguageLinksColumn = ({ items }: Props) => {
  const handleChangeLocale = useChangeLocale();

  return (
    <ul className="w-full text-white">
      {items.map((language) => (
        <li key={language.title} className="mb-4">
          <HeaderMenuListItem
            hasChildren={false}
            value={language.title as string}
            data-test={`language-${language.locale!}`}
            handleSelect={() => handleChangeLocale(language.locale!)}
            lang={language.locale!}
          />
        </li>
      ))}
    </ul>
  );
};
