import {
  NavigationImage as ContentfulNavigationImage,
  Cta,
  Navigation,
  NavigationSubsection,
} from '@graphql/generated-contentful/graphql';
import { Link } from '@lib/types/common/Link';
import { ButtonStyle } from 'ui-components';
import { ContentfulType, NavigationColumn, NavigationItem } from './types';

const mapNavigationSubsection = (column: NavigationSubsection) => {
  return {
    title: column.heading || '',
    highlightFirstLink: column.highlightFirstLink || false,
    items: column.linkCollection?.items
      ?.map((link) => ({
        value: link?.value || '',
        href: link?.url || '',
        openInNewTab: link?.openInNewTab || false,
      }))
      .filter((link) => link) as Link[],
  };
};

const mapCta = (column: Cta) => {
  return {
    value: column.value || '',
    href: column.url || '',
    openInNewTab: column.openInNewTab || false,
    appearance: (column.appearance as ButtonStyle) || 'primary',
  };
};

const mapNavigationImage = (column: ContentfulNavigationImage) => {
  return {
    src: column.image?.url || '',
    alt: column.image?.title || '',
    description: column.description || '',
    link: {
      value: column.link?.value || '',
      href: column.link?.url || '',
    },
  };
};

// Maps the Contentful Navigation items to our generic Navigation types
export const contentfulNavFormat = (
  contentfulNavItems: Navigation[]
): NavigationItem[] => {
  const navItems: NavigationItem[] = [];

  contentfulNavItems.forEach((navItem) => {
    if (navItem.__typename === 'Navigation') {
      const megaMenuNavItem: NavigationItem = {
        mainLink: {
          value: navItem.mainLink?.value || '',
          href: navItem.mainLink?.url || '',
          openInNewTab: navItem.mainLink?.openInNewTab || false,
        },
        isRightAligned: !!navItem.isRightAligned,
        columns: navItem.menuSubsectionCollection?.items
          ?.map((column): NavigationColumn | null => {
            if (!column) {
              return null;
            }
            switch (column.__typename) {
              case ContentfulType.NavigationSubsection:
                return mapNavigationSubsection(column);
              case ContentfulType.NavigationImage:
                return mapNavigationImage(column);
              case ContentfulType.Cta:
                return mapCta(column);
            }
            return null;
          })
          .filter((column) => column) as NavigationColumn[],
      };
      navItems.push(megaMenuNavItem);
    }
  });

  return navItems;
};
