'use client';
import { signIn, signOut } from '@lib/auth';
import { AUTH_SYNC_COOKIE_NAME } from '@lib/constants/auth';
import Cookie from 'js-cookie';
import { useSession } from 'next-auth/react';
import { stringToBoolean } from 'utils';

export const WithAuthSync = () => {
  const session = useSession();
  const authSyncCookie = Cookie.get(AUTH_SYNC_COOKIE_NAME);

  if (
    authSyncCookie &&
    stringToBoolean(authSyncCookie) &&
    session.status === 'unauthenticated'
  ) {
    signIn({ clearAuthSync: true });
  }

  if (
    authSyncCookie &&
    stringToBoolean(authSyncCookie) === false &&
    session.status === 'authenticated'
  ) {
    signOut({ clearAuthSync: true });
  }

  return null;
};
