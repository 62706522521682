import c from 'classnames';

import { CTA } from '@lib/types/common/CTA';
import { LinkButton } from 'ui-components';
import {
  NavigationColumn,
  NavigationCurrencyLinks,
  NavigationImage,
  NavigationLanguageLinks,
  NavigationLinks,
} from '../types';
import { NavigationCurrencyLinksColumn } from './NavigationCurrencyLinksColumn';
import { NavigationImageColumn } from './NavigationImageColumn';
import { NavigationLanguageLinksColumn } from './NavigationLanguageLinksColumn';
import NavigationLinksColumn, {
  LIST_OVERFLOW_COUNT,
} from './NavigationLinksColumn';
const SELL_ASSETS_COL_AMOUNT = 5;
const SELL_ASSETS_COL_WITH_SPACING = 3;

const isColumnLanguageLinks = (
  column: NavigationColumn
): column is NavigationLanguageLinks => {
  return (
    (column as NavigationLanguageLinks).items?.find((i) => i)?.locale !==
    undefined
  );
};

const isColumnCurrencyLinks = (
  column: NavigationColumn
): column is NavigationCurrencyLinks => {
  return (
    (column as NavigationCurrencyLinks).items?.find((i) => i)?.currencyCode !==
    undefined
  );
};

const isColumnNavigationLinks = (
  column: NavigationColumn
): column is NavigationLinks => {
  return (column as NavigationLinks).items !== undefined;
};

const isColumnCTA = (column: NavigationColumn): column is CTA => {
  return (column as CTA).href !== undefined;
};

const isColumnImage = (column: NavigationColumn): column is NavigationImage => {
  return (column as NavigationImage).src !== undefined;
};

// Amount of grid columns an element should take up, mapped to the amount of width they should take up in a flexbox
const columnWidthMap = {
  small: 'lg:w-[16%]',
  medium: 'lg:w-[24%]',
  large: 'lg:w-[32%]',
};

/**
 * Grid Column Rules - Desktop:
 * - Imagining each MegaMenu is a 12 column grid: we use flexbox so that we can use margin-auto to align columns
 * - Each LinkList can be either 1 or 2 columns of links (overflows to 2 columns at 7 or more links)
 *   - Each LinkList column takes up 2 columns - 16% width in the main grid
 *   - So, LinkList can take up to 4 possible columns - 32% width
 * - A CTA column takes up 3 columns - 24% width in the main grid
 * - A Image column takes up 3 columns - 24% width the main grid
 *
 * Mobile + Tablet:
 * - Columns take full width
 * - Images aren't shown on mobile
 */

export const getColumnElement = (
  column: NavigationColumn,
  index: number,
  allColumns: NavigationColumn[]
) => {
  if (isColumnLanguageLinks(column)) {
    return (
      <NavigationLanguageLinksColumn
        key={`${column.title}-${index}`}
        items={column.items}
      />
    );
  }

  if (isColumnCurrencyLinks(column)) {
    return (
      <NavigationCurrencyLinksColumn
        key={`${column.title}-${index}`}
        items={column.items}
      />
    );
  }

  if (isColumnNavigationLinks(column)) {
    return (
      <NavigationLinksColumn
        key={`${column.title}-${index}`}
        items={column.items}
        title={column.title}
        highlightFirstLink={column.highlightFirstLink}
        className={c('w-[100%]', {
          [columnWidthMap['small']]: column.items.length < LIST_OVERFLOW_COUNT,
          [columnWidthMap['large']]: column.items.length >= LIST_OVERFLOW_COUNT,
          /**
           * Special cases: right now all menus have spacing between the first column and the rest
           * except for Sell Assets, which has 5 columns. If there is 5 columns, then the spacing goes only before the final item.
           * If we want more flexibility with this, we can look at adding config options to the CMS to handle different kinds of spacing between columns
           */
          'lg:mr-auto':
            (allColumns.length !== SELL_ASSETS_COL_AMOUNT && index === 0) ||
            (allColumns.length === SELL_ASSETS_COL_AMOUNT &&
              index === SELL_ASSETS_COL_WITH_SPACING),
        })}
      />
    );
  }

  if (isColumnCTA(column)) {
    return (
      <div
        key={`${column.value}-${index}`}
        className={`w-[100%] ${columnWidthMap['medium']}`}
      >
        <LinkButton
          label={column.value}
          href={column.href}
          openLinkInNewTab={column.openInNewTab}
          style={column.appearance}
          isDarkMode={true}
          className="block h-fit w-fit py-2 text-center lg:ml-auto"
        />
      </div>
    );
  }

  if (isColumnImage(column)) {
    return (
      <NavigationImageColumn
        key={`${column.src}-${column.description}`}
        src={column.src}
        alt={column.alt}
        description={column.description}
        link={column.link}
        className={c(columnWidthMap['medium'], 'hidden lg:block')}
      />
    );
  }

  return null;
};
