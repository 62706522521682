import c from 'classnames';
import { CaretRight } from 'phosphor-icons';
import { DynamicIcon } from 'phosphor-icons/react';
import { Link } from 'ui-components';

export type HeaderMenuListItemProps = {
  hasChildren?: boolean;
  value: string;
  href?: string;
  openInNewTab?: boolean;
  iconName?: string;
  classNames?: string;
  lang?: string /* for untranslated menu items like the Language Links. */;
  handleSelect?: () => void;
  testId?: string;
};

export const HeaderMenuListItem = ({
  hasChildren = false,
  value,
  href,
  iconName,
  openInNewTab = false,
  handleSelect,
  classNames,
  lang,
  testId = '',
}: HeaderMenuListItemProps) => {
  const buttonClassNames = c(
    'group focus-outline rounded text-white hover:text-blue flex items-center justify-between cursor-pointer w-full',
    classNames
  );

  const content = (
    <div className="flex items-center">
      {iconName && (
        <DynamicIcon
          iconName={iconName}
          width={20}
          height={20}
          className="mr-2 fill-grey-400 transition group-hover:fill-blue"
        />
      )}
      {value}
    </div>
  );
  return handleSelect ? (
    <button
      data-test={testId}
      className={buttonClassNames}
      onClick={handleSelect}
      lang={lang}
    >
      {content}
      {hasChildren && <CaretRight size={16} className="ml-4 fill-white" />}
    </button>
  ) : (
    <Link
      data-test={testId}
      href={href}
      target={openInNewTab ? '_blank' : '_self'}
      className={buttonClassNames}
      lang={lang}
    >
      {content}
    </Link>
  );
};
