export type Organization = {
  id: string;
  name: string;
  role: OrganizationRole;
  slug: string;
  description?: string;
  company?: string;
  email?: string;
};

export enum OrganizationRole {
  OWNER = 'owner',
  MANAGER = 'manager',
  USER = 'user',
}
