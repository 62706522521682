import { CartContext } from '@contexts/CartContext';
import { FastActionsMenuItemFragment } from '@graphql/generated-contentful/graphql';
import { IconWithCount } from '@ui-components/index';
import { ForwardedRef, useContext } from 'react';
import { IconMenuOption } from '../Header/IconMenuOption';

type FastActionMenuCartProps = {
  item: FastActionsMenuItemFragment;
  forwardedRef: ForwardedRef<HTMLDivElement>;
  referenceProps: {};
};

export const FastActionMenuCart = ({
  item,
  forwardedRef,
  referenceProps,
}: FastActionMenuCartProps) => {
  const { cart } = useContext(CartContext);
  return (
    <IconMenuOption
      name={`${item.topLevelLink?.value}: ${cart?.ItemCount || 0}`}
      href={item.topLevelLink?.url!}
      ref={forwardedRef}
      {...referenceProps}
      className="relative rounded-full"
      target={item.topLevelLink?.openInNewTab ? '_blank' : undefined}
    >
      <IconWithCount
        iconName={item.topLevelLink?.icon!}
        iconSize={20}
        count={cart?.ItemCount || 0}
      />
    </IconMenuOption>
  );
};
