import { AtomicSearch } from '@components/Search/AtomicSearch';
import { AtomicSearchInterfaceWrapper } from '@components/Search/AtomicSearchInterfaceWrapper';
import { AtomicSearchContext } from '@contexts/coveo/AtomicSearchContext';
import { useFlags } from '@lib/flags/amplitude/client';
import { FLAG_TREATMENT, Flags } from '@lib/flags/flagConstants';
import { usePathname } from 'next/navigation';
import { getWithoutLocale } from 'utils';
import { HeaderSearch } from './Search';

/* Handles returning either the old Search or the Atomic Coveo Searchbox */
export const SearchWrapper = ({
  searchPlaceholder,
}: {
  searchPlaceholder: string;
}) => {
  const { flags, loading } = useFlags();
  const isCoveoSearchEnabled =
    flags?.[Flags.COVEO_SEARCH]?.value === FLAG_TREATMENT;
  const pathname = usePathname() || '/';
  const shouldExecuteFirstSearch =
    getWithoutLocale(pathname) === '/search' ||
    getWithoutLocale(pathname) === '/listing';

  if (loading) {
    return null;
  }

  // always load in AtomicSearchInterfaceWrapper as the /search page relies on having this interface loaded
  return (
    <AtomicSearchInterfaceWrapper
      id="atomic-search-interface_search"
      shouldExecuteFirstSearch={shouldExecuteFirstSearch}
      reflectStateInUrl={true}
      context={AtomicSearchContext}
    >
      {isCoveoSearchEnabled ? (
        <AtomicSearch />
      ) : (
        <HeaderSearch placeholder={searchPlaceholder} />
      )}
    </AtomicSearchInterfaceWrapper>
  );
};
