'use client';
import { ChangeEvent } from 'react';
import { useCurrency } from 'src/contexts/CurrencyContext';
import { Select } from 'ui-components';

const currencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
];

export const FooterCurrencySelector = ({
  ariaLabel,
}: {
  ariaLabel: string;
}) => {
  const { currency, setCurrency } = useCurrency();
  const handleCurrencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCurrency(event.target.value);
  };

  /**
   * If the currency is not yet loaded from the ContextProvider, we don't want to render the component.
   */
  if (currency === null) {
    return null;
  }

  return (
    <Select
      id="currency"
      options={currencyOptions}
      selectedOption={currency}
      ariaLabel={ariaLabel}
      onChange={handleCurrencyChange}
    />
  );
};
