'use client';

import { useOrganizations } from '@contexts/OrganizationsContext';
import { Dropdown } from 'ui-components/src/components/Dropdown/Dropdown';

type OrganizationSwitcherProps = {
  switchOrganizationMicrocopy?: string;
};

export const OrganizationSwitcher = ({
  switchOrganizationMicrocopy = 'Switch Organization',
}: OrganizationSwitcherProps) => {
  const {
    organizations,
    activeOrganization,
    handleOrgChange,
    errorFetchingOrganizations,
  } = useOrganizations();

  if (errorFetchingOrganizations || organizations.length === 0) {
    return null;
  }

  return (
    <div className="mt-3 border-y border-grey-800 py-5 lg:px-4">
      <h2 id="switch-organization-label" className="caption mb-2 text-grey-500">
        {switchOrganizationMicrocopy}
      </h2>
      <Dropdown
        id="switch-org-dropdown"
        options={organizations.map((org) => ({
          value: org.id,
          label: org.name,
        }))}
        selectedOption={
          activeOrganization && {
            value: activeOrganization.id,
            label: activeOrganization.name,
          }
        }
        onSelect={(option) =>
          handleOrgChange(organizations.find((org) => org.id === option.value)!)
        }
        ariaLabelledBy="switch-organization-label"
        maxDropdownHeight={170}
      />
    </div>
  );
};
