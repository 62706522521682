import { FastActionsMenuItemFragment } from '@graphql/generated-contentful/graphql';
import { Maybe } from '@lib/types/Maybe';
import { FastActionMenuItem } from './FastActionMenuItem';

type FastActionMenuProps = {
  showLoggedInOptions: boolean;
  items: Maybe<FastActionsMenuItemFragment>[];
};

export const FastActionMenu = ({
  showLoggedInOptions,
  items,
}: FastActionMenuProps) => {
  const filterAuthOptions = (
    fastActionsMenuItem: FastActionsMenuItemFragment
  ) => {
    if (fastActionsMenuItem?.requiresLogIn) {
      return showLoggedInOptions;
    }
    return fastActionsMenuItem;
  };

  const filteredItems = items.filter(filterAuthOptions);

  return (
    <div className="flex">
      {filteredItems.map((fastActionsMenuItem) => (
        <FastActionMenuItem
          item={fastActionsMenuItem as FastActionsMenuItemFragment}
          key={`${fastActionsMenuItem?.topLevelLink?.value}-${fastActionsMenuItem?.topLevelLink?.url}`}
        />
      ))}
    </div>
  );
};
