'use client';

import { useParams, usePathname, useRouter } from 'next/navigation';
import { PropsWithChildren, useMemo } from 'react';
import { DEFAULT_LOCALE, getWithoutLocale } from 'utils';
import { RouterContext, RouterContextType } from './RouterContext';

/**
 * Uses next/navigation as router, should be used only within the app directory
 * Currently a stub, as internationalization isn't implemented in app directory yet.
 * */
export const AppRouterProvider = ({ children }: PropsWithChildren<{}>) => {
  const router = useRouter();
  const params = useParams();
  const pathname = usePathname();

  const contextValue: RouterContextType = useMemo(() => {
    return {
      locale: (params?.locale as string) || DEFAULT_LOCALE,
      changeLocale: (newLocale: string) => {
        if (pathname && newLocale) {
          /**
           * When on the homepage and selecting the language `pathnameWithoutLocale`
           * will be an empty string, hence the fallback to '/'.
           */
          const pathnameWithoutLocale = getWithoutLocale(pathname) || '/';
          router.push(`/${newLocale}${pathnameWithoutLocale}`);
        }
      },
      push: ({ url, options }) => router.push(url, options),
      reload: () => router.refresh(),
    };
  }, [router, params, pathname]);

  return (
    <RouterContext.Provider value={contextValue}>
      {children}
    </RouterContext.Provider>
  );
};
