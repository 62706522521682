import { FastActionsMenuItemDropdownMenuCollection } from '@graphql/generated-contentful/graphql';
import { toNonNullable } from '@lib/util/toNonNullable';
import { HeaderMenuListItem } from '../Header/Navigation/common/HeaderMenuListItem';
import { Overlay } from '../Overlay';

type FastActionMenuDropdownProps = {
  title: string;
  dropdownMenu: FastActionsMenuItemDropdownMenuCollection;
  onBlur?: () => void;
};

export const FastActionMenuDropdown = ({
  title,
  dropdownMenu,
  onBlur = () => {},
}: FastActionMenuDropdownProps) => {
  const onListItemBlur = (e: React.FocusEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      onBlur();
    }
  };
  return (
    <>
      <Overlay />
      <ul className="z-modal absolute right-0 top-12 w-72 rounded-md bg-black px-4 py-6 md:-right-8">
        <div role="presentation" onBlur={onListItemBlur}>
          <div className="caption pb-6 text-left text-grey-500">{title}</div>
          {dropdownMenu.items.map((item) => (
            <li key={item?.url} className="pb-4 last:pb-0">
              <HeaderMenuListItem
                href={item?.url!}
                openInNewTab={Boolean(item?.openInNewTab)}
                iconName={toNonNullable(item?.icon)}
                value={item?.value!}
              />
            </li>
          ))}
        </div>
      </ul>
    </>
  );
};
