import c from 'classnames';
import { IconButton } from 'ui-components';

export type MenuBackButtonProps = {
  ariaLabel: string;
  className?: string;
  handleClick: () => void;
};
export const MenuBackButton = ({
  ariaLabel,
  className,
  handleClick,
}: MenuBackButtonProps) => (
  <IconButton
    iconName="CaretLeft"
    ariaLabel={ariaLabel}
    className={c(
      'absolute left-0 top-0 mx-2 my-3 w-[2.25rem] p-2 md:-left-12 md:mt-0',
      className
    )}
    iconClassName="fill-grey-400"
    onClick={handleClick}
  />
);
