import { User } from '@lib/types/common/User';
import { FocusOn } from 'react-focus-on';
import { UserMenuContent } from './UserMenuContent';
import { UserMenuSection } from './types';

type UserMenuDropdownProps = {
  isActive: boolean;
  toggleDropdown: () => void;
  toggleButtonRef: React.RefObject<HTMLDivElement>;
  sections: UserMenuSection[];
  user?: User;
  microcopy: Record<string, string>;
};

/** Used to display the user menu in a dropdown on Desktop */
export const UserMenuDropdown = ({
  isActive,
  toggleDropdown,
  toggleButtonRef,
  sections,
  user,
  microcopy,
}: UserMenuDropdownProps) => {
  return (
    <FocusOn
      returnFocus
      enabled={isActive}
      onEscapeKey={toggleDropdown}
      onClickOutside={toggleDropdown}
      shards={[toggleButtonRef]}
      className="hidden lg:block"
    >
      <div className="z-modal absolute right-0 top-full w-72 rounded-lg bg-black">
        <UserMenuContent
          sections={sections}
          user={user}
          switchOrganizationMicrocopy={microcopy['layout.switch-organization']}
        />
      </div>
    </FocusOn>
  );
};
