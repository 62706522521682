'use client';

import { useCurrency } from '@contexts/CurrencyContext';
import { analytics } from '@lib/analytics/page-events';
import { GTM_LOADED_EVENT } from '@lib/constants/gtm';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

export const WithAnalytics = () => {
  const session = useSession();
  const { currency } = useCurrency();

  const fireAnalyticsInitialize = () => {
    analytics.initialize(
      session?.data?.accessToken?.sub,
      session?.data?.user?.email ?? undefined,
      currency
    );
  };

  useEffect(() => {
    window.addEventListener(GTM_LOADED_EVENT, fireAnalyticsInitialize);

    return () => {
      window.removeEventListener(GTM_LOADED_EVENT, fireAnalyticsInitialize);
    };
    // only want to run on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
