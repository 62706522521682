import {
  ACTIVE_ORGANIZATION_ID_COOKIE,
  COOKIE_EXPIRY_DAYS,
} from '@lib/constants/cookies';
import { failingFetcher } from '@lib/http/fetch';
import { Organization } from '@lib/types/api/Organization';
import { User } from '@lib/types/common/User';
import Cookies from 'js-cookie';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSWR from 'swr';

export const OrganizationsContext = createContext<{
  organizations: Organization[];
  activeOrganization: Organization | null;
  handleOrgChange: (organization: Organization) => void;
  errorFetchingOrganizations: any;
}>({
  organizations: [],
  activeOrganization: null,
  handleOrgChange: () => {},
  errorFetchingOrganizations: null,
});

export const OrganizationsProvider = ({
  user,
  children,
}: PropsWithChildren<{ user?: User }>) => {
  const { data, error } = useSWR<{
    organizations: Organization[];
    defaultOrganizationId: string;
  }>(
    // only fetch if there is a user logged in
    user ? `/api/users/organizations` : null,
    failingFetcher
  );

  const { organizations, defaultOrganizationId } = useMemo(() => {
    return {
      organizations: data?.organizations ? data.organizations : [],
      defaultOrganizationId: data?.defaultOrganizationId,
    };
  }, [data]);

  const [activeOrganization, setActiveOrganization] =
    useState<Organization | null>(null);

  // On component mount, check for stored organization ID in cookies
  // If found and valid, set as active organization
  // If not found or invalid, set default organization as active
  // If no defaultOrganizationId, set the first org in the org list as active
  useEffect(() => {
    const storedOrgId = Cookies.get(ACTIVE_ORGANIZATION_ID_COOKIE);
    const storedOrg = organizations.find((org) => org.id === storedOrgId);

    if (storedOrg) {
      setActiveOrganization(storedOrg);
    } else if (organizations.length > 0) {
      const activeOrg =
        organizations.find((org) => org.id === defaultOrganizationId) ??
        organizations[0];

      handleOrgChange(activeOrg);
    }
  }, [organizations, defaultOrganizationId]);

  // Handle change in selected organization
  // Update active organization state and store new ID in cookies
  const handleOrgChange = (org: Organization) => {
    setActiveOrganization(org);
    Cookies.set(ACTIVE_ORGANIZATION_ID_COOKIE, org.id, {
      expires: COOKIE_EXPIRY_DAYS,
    });
  };

  const value = useMemo(() => {
    return {
      organizations,
      errorFetchingOrganizations: error,
      activeOrganization,
      handleOrgChange,
    };
  }, [activeOrganization, organizations, error]);

  return (
    <OrganizationsContext.Provider value={value}>
      {children}
    </OrganizationsContext.Provider>
  );
};

export const useOrganizations = () => {
  return useContext(OrganizationsContext);
};
