'use client';

import { SessionProvider, SessionProviderProps } from 'next-auth/react';

export const AuthSessionProvider = ({
  children,
  ...props
}: SessionProviderProps) => {
  return <SessionProvider {...props}>{children}</SessionProvider>;
};
