import { GENESIS_PROVIDER_ID } from '@lib/api/auth/genesis-provider';
import {
  AUTH_SYNC_COOKIE_NAME,
  AuthSyncCookieStatus,
} from '@lib/constants/auth';
import { ACTIVE_ORGANIZATION_ID_COOKIE } from '@lib/constants/cookies';
import { resetSearchTokens } from '@lib/coveo/searchTokenHelpers';
import Cookie from 'js-cookie';
import {
  signIn as signInNextAuth,
  signOut as signOutNextAuth,
} from 'next-auth/react';

type Options = {
  /**
   * Used to remove the `auth_sync` Session cookie that's used to sync authentication state betwen ASV2 and Compass UI.
   * The reason we want to clear this cookie is when an automatic log in/out-sequence happen we don't want
   * subsequent page loads to re-try the behavior if the action failed or the user decided to not follow through.
   */
  clearAuthSync: boolean;
};

export const signIn = (options?: Options) => {
  if (options?.clearAuthSync) {
    Cookie.remove(AUTH_SYNC_COOKIE_NAME);
  } else {
    Cookie.set(AUTH_SYNC_COOKIE_NAME, AuthSyncCookieStatus.TRUE);
  }
  // clear the anonymous search token from localStorage so an authenticated one can be generated
  resetSearchTokens();
  return signInNextAuth(GENESIS_PROVIDER_ID);
};

export const signOut = async (options?: Options) => {
  if (options?.clearAuthSync) {
    Cookie.remove(AUTH_SYNC_COOKIE_NAME);
  } else {
    Cookie.set(AUTH_SYNC_COOKIE_NAME, AuthSyncCookieStatus.FALSE);
  }

  // clear the authenticated search token from localStorage so an anonymous one can be generated
  resetSearchTokens();

  // remove active organization from cookies
  Cookie.remove(ACTIVE_ORGANIZATION_ID_COOKIE);

  // sign out of next-auth for Compass UI
  await signOutNextAuth();

  const logoutParams = new URLSearchParams({
    post_logout_redirect_uri: (process.env.NEXT_PUBLIC_SITE_URL || '') + '/',
  }).toString();

  // sign out of Unity ID
  window.location.href = `${process.env.NEXT_PUBLIC_UNITY_API}/oauth2/end-session?${logoutParams}`;
};
