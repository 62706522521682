import { useRouter } from '@contexts/Router/RouterContext';
import {
  COOKIE_EXPIRY_DAYS,
  LANGUAGE_COOKIE_NAME,
} from '@lib/constants/cookies';
import Cookie from 'js-cookie';

export const useChangeLocale = () => {
  const router = useRouter();

  function handleLocaleChange(nextLocale: string) {
    router.changeLocale(nextLocale);

    /**
     * We're setting this cookie for ASv2 backwards-compatibility.
     */
    Cookie.set(LANGUAGE_COOKIE_NAME, nextLocale, {
      expires: COOKIE_EXPIRY_DAYS,
    });
  }

  return handleLocaleChange;
};
