import { EnvironmentVariable, getEnvVar } from '@lib/config';
import { GenesisError } from '@lib/types/errors';

type GenesisAuthClient = {
  getAccessToken(
    callbackUrl: string,
    code: string
  ): Promise<GenesisOauthTokenResponse>;
  refreshAccessToken(refreshToken: string): Promise<GenesisOauthTokenResponse>;
};

type GenesisOauthTokenResponse = {
  access_token: string;
  token_type: string;
  expires_in: string;
  refresh_token: string;
  user: string;
  display_name: string;
};

const GRANT_TYPE = {
  AUTHORIZATION_CODE: 'authorization_code',
  REFRESH_TOKEN: 'refresh_token',
};

const unityIdClientId = getEnvVar(EnvironmentVariable.unityIdClient);
const unityIdClientSecret = getEnvVar(EnvironmentVariable.unityIdSecret);

export function createGenesisAuthClient(baseURL: string): GenesisAuthClient {
  const getAccessToken = async (
    callbackUrl: string,
    code: string
  ): Promise<GenesisOauthTokenResponse> => {
    const body = {
      grant_type: GRANT_TYPE.AUTHORIZATION_CODE,
      redirect_uri: callbackUrl,
      code,
      client_id: unityIdClientId,
      client_secret: unityIdClientSecret,
    };
    const response = await fetch(`${baseURL}/oauth2/token`, {
      method: 'POST',
      body: new URLSearchParams(body).toString(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });

    const data = await response.json();

    if (!response.ok) {
      const error = data as GenesisError;
      throw new Error(
        `Fetching access token failed with status ${
          response.status
        }. Reason: ${JSON.stringify(error.details)}`
      );
    }

    return data;
  };

  const refreshAccessToken = async (
    refreshToken: string
  ): Promise<GenesisOauthTokenResponse> => {
    const body = {
      grant_type: GRANT_TYPE.REFRESH_TOKEN,
      client_id: unityIdClientId,
      client_secret: unityIdClientSecret,
      refresh_token: refreshToken,
    };

    const response = await fetch(`${baseURL}/oauth2/token`, {
      method: 'POST',
      body: new URLSearchParams(body).toString(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });

    return response.json();
  };

  return {
    getAccessToken,
    refreshAccessToken,
  };
}
