import { Overlay } from '@components/Layout/Overlay';
import { User } from '@lib/types/common/User';
import { OrganizationSwitcher } from './OrganizationSwitcher/OrganizationSwitcher';
import { UserInfo } from './UserInfo';
import { UserMenuSection } from './UserMenuSection';
import { UserMenuSection as UserMenuSectionType } from './types';

type UserMenuContentProps = {
  user?: User;
  sections: UserMenuSectionType[];
  className?: string;
  switchOrganizationMicrocopy?: string;
};

export const UserMenuContent = ({
  user,
  sections,
  className = '',
  switchOrganizationMicrocopy,
}: UserMenuContentProps) => {
  return (
    <>
      <Overlay />
      <div className={`z-modal relative ${className}`}>
        {user && <UserInfo user={user} />}
        {user && (
          <OrganizationSwitcher
            switchOrganizationMicrocopy={switchOrganizationMicrocopy}
          />
        )}
        <ul>
          {sections.map((section) => {
            return (
              <UserMenuSection
                key={section.items.reduce((acc, item) => acc + item.value, '')}
                display={section.display}
                items={section.items}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};
