import c from 'classnames';

import { useCurrency } from '@contexts/CurrencyContext';
import { useRouter } from '@contexts/Router/RouterContext';
import { AnimationStates } from 'ui-components';
import { LOCALE_TO_DISPLAY_NAME } from 'utils';
import { HeaderMenuListItem } from '../common/HeaderMenuListItem';
import { NavigationItem } from '../types';

type MobileMainNavigationProps = {
  items: NavigationItem[];
  languages: NavigationItem;
  currencies: NavigationItem;
  animationStates: AnimationStates;
  handleItemSelect: (item: NavigationItem) => void;
};

export const MobileMainNavigation = ({
  items,
  languages,
  currencies,
  handleItemSelect,
  animationStates,
}: MobileMainNavigationProps) => {
  const router = useRouter();
  const { locale } = router;
  const selectedLanguage = LOCALE_TO_DISPLAY_NAME[locale];
  const { currency } = useCurrency();

  return (
    <div
      className={c('mb-10 w-full transition duration-300', {
        'opacity-1 ': animationStates.isAnimatedIn,
        'opacity-0': animationStates.isAnimating,
      })}
    >
      {/* Main Menu */}
      <ul>
        {items.map((item) => (
          <li key={item.mainLink.value}>
            <HeaderMenuListItem
              hasChildren={item.columns && item.columns.length > 0}
              value={item.mainLink.value}
              href={item.mainLink.href}
              handleSelect={() => handleItemSelect(item)}
              classNames="py-4"
            />
          </li>
        ))}
      </ul>
      {/* Languages */}
      <div className="mt-10">
        <div className="caption text-grey-500">{languages.mainLink.value}</div>
        <ul>
          <HeaderMenuListItem
            hasChildren={true}
            value={selectedLanguage}
            handleSelect={() => handleItemSelect(languages)}
            classNames="py-4"
          />
        </ul>
      </div>
      {/* Currencies */}
      <div className="mt-10">
        <div className="caption text-grey-500">{currencies.mainLink.value}</div>
        <ul>
          <HeaderMenuListItem
            hasChildren={true}
            value={currency}
            handleSelect={() => handleItemSelect(currencies)}
            classNames="py-4"
          />
        </ul>
      </div>
    </div>
  );
};
