import * as urls from '@lib/constants/urls';
import c from 'classnames';
import { useRouter } from 'next/navigation';
import { MagnifyingGlass, X } from 'phosphor-icons';
import { ChangeEvent, useRef, useState } from 'react';
import xss from 'xss';

type Props = {
  onChange?: (nextState: string) => void;
  placeholder?: string;
  suggestions?: {
    label: string;
    onClick: () => void;
  }[];
  suggestionLabel?: string;
};

const INPUT_DEFAULT_STATE = '';

export const HeaderSearch = ({
  onChange,
  placeholder,
  suggestions,
  suggestionLabel,
}: Props) => {
  const router = useRouter();
  const [inputState, setInputState] = useState<string>(INPUT_DEFAULT_STATE);
  const inputRef = useRef<HTMLInputElement>(null);

  const changeInputState = (nextState: string) => {
    setInputState(nextState);

    if (onChange) {
      return onChange(nextState);
    }

    return null;
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeInputState(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const sanitizedQuery = xss(inputState);
    // Redirect to the search results page with the query parameter
    router.push(urls.SEARCH_QUERY_URL(encodeURIComponent(sanitizedQuery)));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const handleOnClearInputClick = () => {
    if (inputRef.current) {
      changeInputState(INPUT_DEFAULT_STATE);
      inputRef.current.focus();
    }
  };

  const showClearInputButton = inputState.length > 0;

  return (
    <fieldset className="relative flex items-center rounded bg-grey-700">
      <legend className="sr-only">{placeholder}</legend>
      <MagnifyingGlass
        width={16}
        height={16}
        className="pointer-events-none absolute left-4"
      />
      <input
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        value={inputState}
        type="text"
        placeholder={placeholder}
        className="block w-full bg-transparent py-2 pl-12 pr-6 text-white"
        ref={inputRef}
      />
      <button
        className={c('absolute right-2 p-2', {
          hidden: !showClearInputButton,
        })}
        onClick={handleOnClearInputClick}
      >
        <X height={15} width={15} fill="white" />
      </button>
      {suggestions && (
        <div className="absolute left-0 top-full w-full rounded-md bg-black px-8 py-6 text-white">
          <h1 className="mb-2 ml-4 text-xs text-grey-600">{suggestionLabel}</h1>
          <ul>
            {suggestions.map((suggestion) => (
              <li
                className="w-full cursor-pointer rounded-md px-4 py-3 hover:bg-grey-900"
                key={suggestion.label}
              >
                <button onClick={suggestion.onClick}>{suggestion.label}</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </fieldset>
  );
};
