import c from 'classnames';

import { Link } from '@lib/types/common/Link';
import { NavigationLinks } from '../types';
import NavigationLink from './NavigationLink';

/**
 * Dynamically building classnames with Tailwind requires that
 * the classes are mentioned _somewhere_ in the code, to avoid
 * PostCSS purging classnames that we could potentially need
 * from the final CSS bundle. This lookup table is a workaround
 * to ensure that potential col-start classes are not purged.
 */
const colStartMap = {
  1: 'lg:col-start-1',
  2: 'lg:col-start-2',
};

export const LIST_OVERFLOW_COUNT = 9;
export const LIST_MID_COUNT = 12;

/**
 * Link List Column Rules:
 * - Each LinkList can be either 1 or 2 columns of links:
 *   - < 7 links: 1 column
 *   - 7-12 links (inclusive): items start to wrap to 2nd column
 *   - > 12 links: items split evenly between 2 columns
 */
const getColumns = (items: Link[]): Link[][] => {
  if (items.length < LIST_OVERFLOW_COUNT) {
    return [items];
  } else if (
    items.length >= LIST_OVERFLOW_COUNT &&
    items.length <= LIST_MID_COUNT
  ) {
    return [
      items.slice(0, LIST_OVERFLOW_COUNT - 1),
      items.slice(LIST_OVERFLOW_COUNT - 1),
    ];
  } else {
    // items.length >= 12
    // if odd, have more items in the first column
    return [
      items.slice(0, Math.ceil(items.length / 2)),
      items.slice(Math.ceil(items.length / 2)),
    ];
  }
};

type Props = {
  className?: string;
} & NavigationLinks;

const NavigationLinksColumn = ({
  title,
  items,
  highlightFirstLink,
  className,
}: Props) => {
  const columns = getColumns(items);
  const hasCol2 = columns?.length > 1;

  return (
    <div className={className}>
      <h2 className="caption mb-6 text-grey-500">{title}</h2>
      <ul
        /**
         * We use CSS grid instead of CSS columns here since CSS columns always splits items evenly between the 2 columns.
         * Because of the 7-12 usecase of not splitting items evenly, we need to do some custom logic
         */
        className={c('grid grid-flow-col gap-x-4', {
          'grid-cols-1': !hasCol2,
          'grid-cols-1 lg:grid-cols-2': hasCol2,
        })}
      >
        {columns.map((column, columnIndex) =>
          column.map((link, index) => (
            <NavigationLink
              key={title + link.value}
              className={`col-span-1 col-start-1 ${
                colStartMap[columnIndex + 1]
              }`}
              highlight={columnIndex === 0 && highlightFirstLink && index === 0}
              {...link}
            />
          ))
        )}
      </ul>
    </div>
  );
};

export default NavigationLinksColumn;
