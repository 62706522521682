'use client';

import { createContext } from 'react';

import { CoveoSearchHub } from '@lib/coveo/coveoConstants';
import '../../styles/coveoCustomCss.css';
import { AtomicContextValue } from './AtomicContext';
import { useCoveoHeadlessEngine } from './useCoveoHeadlessEngine';

export const AtomicSearchContext = createContext<AtomicContextValue>({
  searchEngine: undefined,
  coveoContext: undefined,
});

/**
 * This Context provides the Coveo Atomic Search Engine to all children components.
 * The coveo engine is a redux store that can be passed to Atomic components, and used to access any part of the Coveo state.
 *
 * This AtomicSearchContext is used globally in the app so that the search engine is available for use both in the Header searchbox, as well as on the search page content.
 *
 * AtomicListingContext, which is the same idea but used for product listings, should be used to wrap the specific components that directly use it,
 * since there can be multiple listings on one page at a time.
 */
export const AtomicSearchProvider = ({ children, pipeline }) => {
  const value = useCoveoHeadlessEngine(
    pipeline ? pipeline : CoveoSearchHub.SEARCH,
    CoveoSearchHub.SEARCH
  );

  return (
    <AtomicSearchContext.Provider value={value}>
      {children}
    </AtomicSearchContext.Provider>
  );
};
