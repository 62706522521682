import c from 'classnames';
import Link from 'next/link';
import { PropsWithChildren, forwardRef } from 'react';

type Props = {
  testId?: string;
  name: string;
  onClick?: () => void;
  className?: string;
  target?: string;
  href?: string;
};

const IconMenuOptionComponent = (
  {
    children,
    name,
    onClick,
    testId = '',
    href,
    target,
    className,
    ...rest
  }: PropsWithChildren<Props>,
  ref
) => {
  const classNames = c(
    'w-10 h-10 flex items-center justify-center rounded-full focus-outline hover:bg-grey-700 transition-colors',
    className
  );

  const elementProps = {
    className: classNames,
    'aria-label': name,
    ...(testId ? { 'data-test': testId } : {}),
  };

  if (onClick) {
    return (
      <button {...rest} {...elementProps} onClick={onClick} ref={ref}>
        {children}
      </button>
    );
  }

  if (href) {
    return (
      <Link
        {...rest}
        {...elementProps}
        href={href}
        target={target}
        ref={ref}
        prefetch={false}
      >
        {children}
      </Link>
    );
  }
  return null;
};

export const IconMenuOption = forwardRef(IconMenuOptionComponent);
