import { User } from '@lib/types/common/User';
import { PropsWithChildren } from 'react';
import { Avatar } from 'ui-components';

type Props = {
  user: User;
};

export const UserInfo = ({ user }: PropsWithChildren<Props>) => {
  return (
    <div
      data-test="user-menu-user-info"
      className="flex flex-col items-center gap-3 bg-black px-4 pb-5 pt-6 text-white lg:flex-row lg:pb-0"
    >
      <Avatar
        userName={user.name}
        userProfilePicture={user.profilePicture}
        size={48}
        className="shrink-0"
      />
      <div className="min-w-0 max-w-full">
        <h2 className="body-small overflow-hidden text-ellipsis whitespace-nowrap text-center text-white lg:text-left">
          {user.name}
        </h2>
        <div className="tiny-text overflow-hidden text-ellipsis whitespace-nowrap text-center text-grey-400 lg:text-left">
          {user.email}
        </div>
      </div>
    </div>
  );
};
