import { FunctionComponent, PropsWithChildren } from 'react';

import {
  Footer as FooterType,
  LanguageLink,
  Link,
} from '@graphql/generated-contentful/graphql';
import { toNonNullable } from '@lib/util/toNonNullable';
import { FooterLanguageMenuList } from './FooterLanguageMenuList';
import { FooterLegal } from './FooterLegal';
import { FooterLogo } from './FooterLogo';
import { FooterMenu } from './FooterMenu/FooterMenu';
import { FooterMenuList } from './FooterMenu/FooterMenuList';
import { FooterSocialMedia } from './FooterSocialMedia';

type Props = { footerData: FooterType };

export const Footer: FunctionComponent<PropsWithChildren<Props>> = ({
  footerData,
}) => {
  const menuList = footerData.menuCollection?.items;
  const languageMenu = footerData.languages;
  const socialMediaTitle = footerData.socialMedia?.title;
  const socialMediaLinks = footerData.socialMedia?.menuItemsCollection?.items;

  return (
    <footer className="bg-black pb-10 pt-14">
      <div className="container">
        <FooterLogo
          title={footerData?.logoTitle!}
          src={footerData?.logoImage?.url!}
        />
        <FooterMenu>
          <FooterLanguageMenuList
            label={languageMenu?.title!}
            languages={
              languageMenu?.menuItemsCollection?.items as LanguageLink[]
            }
          />
          {menuList?.map((list) => {
            return (
              <FooterMenuList
                key={list?.title}
                label={toNonNullable(list?.title)}
                items={list?.menuItemsCollection?.items as Link[]}
              />
            );
          })}
          {/* Placeholder column now since Newsletter is removed. */}
          <div className="col-span-12 row-start-1 hidden lg:col-span-4 lg:col-start-9 lg:row-start-1 lg:block"></div>
          <FooterSocialMedia
            label={toNonNullable(socialMediaTitle)}
            links={socialMediaLinks as Link[]}
          />
        </FooterMenu>
        <FooterLegal footerData={footerData} />
      </div>
    </footer>
  );
};
