import { Link } from '@graphql/generated-contentful/graphql';
import { DynamicIcon } from 'phosphor-icons/react';
import { FooterMenuItem } from './FooterMenu/FooterMenuItem';
import { FooterMenuLabel } from './FooterMenu/FooterMenuLabel';

type FooterSoMeProps = {
  label?: string;
  links?: Link[];
};

export const FooterSocialMedia = ({ label, links }: FooterSoMeProps) => {
  if (!links) {
    return null;
  }

  return (
    <ul className="col-span-12 lg:col-span-2 lg:row-auto">
      <FooterMenuItem>
        <FooterMenuLabel text={label!} />
        <div className="flex">
          {links.map((link) => {
            const iconName = link?.icon;
            if (!iconName) {
              return null;
            }
            return (
              <a
                href={link.url!}
                key={link.title!}
                aria-label={link.title!}
                title={link.title!}
                className="flex h-10 w-10 items-center justify-center rounded-full fill-white/75 text-grey-600 transition-colors hover:bg-grey-900 hover:fill-white"
                target="_blank"
              >
                <DynamicIcon
                  iconName={iconName}
                  width={20}
                  height={20}
                  weight="fill"
                  className="mr-0 fill-grey-400 transition group-hover:fill-blue"
                />
              </a>
            );
          })}
        </div>
      </FooterMenuItem>
    </ul>
  );
};
