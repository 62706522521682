import Image from 'next/image';
import Link from 'next/link';

type FooterLogoProps = {
  title: string;
  src: string;
};

export const FooterLogo = ({ title, src }: FooterLogoProps) => {
  if (!src) {
    return null;
  }

  return (
    <Link href={'/'} className="block w-fit" prefetch={false}>
      <Image
        width="92"
        height="32"
        src={src}
        alt={title || 'The Unity Asset Store logo'}
        className="mb-10"
      />
    </Link>
  );
};
