import { Children, PropsWithChildren } from 'react';

export const FooterFootnote = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex w-6/12 flex-col flex-wrap text-xs text-grey-500 lg:w-10/12 lg:flex-row">
      {Children.map(children, (child) => {
        return (
          <div className="mr-3 flex-initial whitespace-nowrap">{child}</div>
        );
      })}
    </div>
  );
};
