'use client';
import { BenefitsBar } from '@components/BenefitsBar/BenefitsBar';
import { PropsWithChildren, useMemo } from 'react';
import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';

import { User } from '@lib/types/common/User';
import { LayoutDataProps } from '@lib/types/queries';
import { AnnouncementBarWrapper } from './AnnouncementBar/AnnouncementBarWrapper';
import { transformBenefitsBar } from './layout-utils';

export default function Layout({
  children,
  layoutData,
  user,
}: PropsWithChildren<{
  layoutData: LayoutDataProps;
  user?: User;
}>) {
  const benefitsBarData = useMemo(() => {
    return transformBenefitsBar(layoutData.benefitsBar);
  }, [layoutData]);
  const announcement =
    layoutData.announcementBar?.announcementsCollection?.items?.[0];

  return (
    <>
      {layoutData.announcementBar && announcement && (
        <AnnouncementBarWrapper announcementBar={layoutData.announcementBar} />
      )}
      {layoutData.header && (
        <Header
          user={user}
          headerData={layoutData.header}
          microcopy={layoutData.microcopy}
          languageMenu={layoutData.footer?.languages}
        />
      )}
      <main id="main" className="focus:outline-0">
        {benefitsBarData && (
          <div className="lg-container py-4 lg:py-6" data-test="benefits-bar">
            <BenefitsBar data={benefitsBarData} />
          </div>
        )}
        {children}
      </main>
      {layoutData.footer && <Footer footerData={layoutData.footer} />}
    </>
  );
}
