import { useOrganizations } from '@contexts/OrganizationsContext';
import {
  EventLinkFragment,
  FastActionsMenuItemFragment,
  LinkFragment,
  UserProfileSectionFragment,
} from '@graphql/generated-contentful/graphql';
import { signIn, signOut } from '@lib/auth';
import { Maybe } from '@lib/types/Maybe';
import { Organization, OrganizationRole } from '@lib/types/api/Organization';
import { Link } from '@lib/types/common/Link';
import { isDefined } from '@lib/util/isDefined';
import { toNonNullable } from '@lib/util/toNonNullable';
import { interpolateStringWithData } from 'utils';
import {
  HeaderMenuButtonListItem,
  HeaderMenuLinkListItem,
} from '../Navigation/common/types';
import {
  UserMenuSection,
  UserMenuSectionDisplay,
  UserMenuSelectedItem,
} from './types';

enum CMSEventName {
  SIGN_IN = 'sign-in',
  NOTIFICATIONS = 'openNotifications',
  SIGN_OUT = 'sign-out',
  ASSIGN_SEATS = 'assign_seats',
}

type UserMenuEventHandlers = {
  setSelectedItem: (item: UserMenuSelectedItem) => void;
};

type UseFormatUserMenuItemsArgs = {
  sections: UserProfileSectionFragment[];
  eventHandlers: UserMenuEventHandlers;
};

function mapCMSEventToClickHandler(eventName: Maybe<CMSEventName> | undefined) {
  switch (eventName) {
    case CMSEventName.SIGN_OUT:
      return () => signOut();
    case CMSEventName.SIGN_IN:
      return () => signIn();
    default:
      return undefined;
  }
}

/**
 * Some links are only displayed in the User Menu when the user is a owner (or not) of the currently active organization.
 *
 * Some links have custom interpolation logic that needs to be used with the active org.
 *
 * For these special cases, we hardcode this logic rather than make very specific rules in the CMS that will only be true for these couple items.
 */
function filterLinksByOrganizationRole(
  link: HeaderMenuLinkListItem,
  activeOrganization: Maybe<Organization>
) {
  const isOwner = activeOrganization?.role === OrganizationRole.OWNER;
  if (
    (link.href === '/orders' || link.href === '/account/voucher') &&
    !isOwner
  ) {
    // only owners can see these links, return null if not an owner
    return null;
  }
  if (link.href === '/order-requests' && isOwner) {
    // everyone except owners can see this link, return null if an owner
    return null;
  }
  if (link.href.includes('seat_managements')) {
    if (!isOwner) {
      return null;
    }
    // assign seats links to https://id.unity.com/organizations/{{slug}}/manage_assets/seat_managements , which needs to include the org's slug
    return {
      ...link,
      href: interpolateStringWithData(link.href, {
        slug: activeOrganization.slug,
      }),
    };
  }

  return link;
}

function mapCMSLinkToLinkItem(item: LinkFragment): HeaderMenuLinkListItem {
  return {
    value: item.value!,
    iconName: item.icon ?? '',
    href: item.url ?? '',
    openInNewTab: Boolean(item.openInNewTab),
  };
}

function mapCMSEventLinkToButtonItem(
  item: EventLinkFragment
): HeaderMenuButtonListItem | null {
  const handleSelect = mapCMSEventToClickHandler(
    item.eventName as CMSEventName
  );

  if (handleSelect) {
    return {
      value: item.title!,
      iconName: item.icon ?? '',
      handleSelect,
    };
  }

  return null;
}

function mapCMSFastActionsDropdownMenuItemsToLinks(
  item: FastActionsMenuItemFragment
): Link[] {
  return (
    item.dropdownMenuCollection?.items
      ?.map((dropdownMenuItem) => {
        if (!dropdownMenuItem) {
          return null;
        }

        return {
          value: dropdownMenuItem.value!,
          href: dropdownMenuItem.url!,
          openInNewTab: Boolean(dropdownMenuItem.openInNewTab),
          icon: toNonNullable(dropdownMenuItem.icon),
        };
      })
      .filter(isDefined) ?? []
  );
}

function mapCMSFastActionsMenuItemToButtonItem(
  item: FastActionsMenuItemFragment,
  eventHandlers: UserMenuEventHandlers
): HeaderMenuButtonListItem | null {
  if (!item.topLevelLink) {
    return null;
  }

  return {
    value: item.topLevelLink.value!,
    iconName: item.topLevelLink.icon ?? '',
    hasChildren: true,
    handleSelect: () =>
      eventHandlers.setSelectedItem({
        heading: item.topLevelLink?.value!,
        links: mapCMSFastActionsDropdownMenuItemsToLinks(item),
      }),
  };
}

function formatCMSSections(
  sections: UserProfileSectionFragment[],
  eventHandlers: UserMenuEventHandlers,
  activeOrganization: Maybe<Organization>
) {
  return sections.map((section) => {
    const items = section.itemsCollection?.items;
    if (!items) {
      return null;
    }

    return {
      display: section.display as UserMenuSectionDisplay,
      items: items
        .map((item) => {
          if (!item) {
            return null;
          }

          if (item.__typename === 'Link') {
            const linkItem = mapCMSLinkToLinkItem(item);
            return filterLinksByOrganizationRole(linkItem, activeOrganization);
          } else if (item?.__typename === 'EventLink') {
            return mapCMSEventLinkToButtonItem(item);
          } else if (item?.__typename === 'FastActionsMenuItem') {
            return mapCMSFastActionsMenuItemToButtonItem(item, eventHandlers);
          }

          // unsupported item type, return null to be filtered out
          return null;
        })
        .filter(isDefined),
    };
  });
}

/**
 * This function is used to format the user menu items from the CMS and map any event handlers.
 */
export const useFormatCMSUserMenuItems = ({
  sections,
  eventHandlers,
}: UseFormatUserMenuItemsArgs): UserMenuSection[] => {
  const { activeOrganization } = useOrganizations();

  return formatCMSSections(sections, eventHandlers, activeOrganization).filter(
    isDefined
  );
};
