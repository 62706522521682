import c from 'classnames';

import { PropsWithChildren } from 'react';
import { AnimationStates } from 'ui-components';
import { MenuBackButton } from './MenuBackButton';

type MobileSubNavigationProps = {
  heading: string;
  animationStates: AnimationStates;
  goBackMicrocopy: string;
  goBack: () => void;
};

export const MobileSubNavigation = ({
  heading,
  animationStates,
  goBackMicrocopy,
  goBack,
  children,
}: PropsWithChildren<MobileSubNavigationProps>) => (
  <>
    {/* Mobile back button placed in top left */}
    <MenuBackButton
      ariaLabel={goBackMicrocopy}
      className="md:hidden"
      handleClick={goBack}
    />
    <div className="sticky top-0 mb-2 bg-black pb-6 pt-2 md:mb-6 md:border-b md:border-grey-800">
      {/* Tablet back button placed next to title */}
      <MenuBackButton
        ariaLabel={goBackMicrocopy}
        className="hidden md:block"
        handleClick={goBack}
      />
      <h2 className="body-small-bold text-white">{heading}</h2>
    </div>
    <div
      className={c(
        'mb-12 flex flex-wrap gap-x-4 gap-y-12 transition duration-300',
        {
          'opacity-1 ': animationStates.isAnimatedIn,
          'opacity-0': animationStates.isAnimating,
        }
      )}
    >
      {children}
    </div>
  </>
);
