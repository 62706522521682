'use client';

import { UserProfileSectionFragment } from '@graphql/generated-contentful/graphql';
import { useWindowSize } from '@lib/hooks/useWindowSize';
import { User } from '@lib/types/common/User';
import { UserCircle } from 'phosphor-icons';
import { useRef, useState } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import { Avatar } from 'ui-components';
import tailwindConfig from '../../../../../tailwind.config.js';
import { IconMenuOption } from '../IconMenuOption';
import { UserMenuDropdown } from './UserMenuDropdown';
import { UserMenuModal } from './UserMenuModal';
import { UserMenuSelectedItem } from './types';
import { useFormatCMSUserMenuItems } from './useFormatUserMenuItems';

const screensThemeConfig = resolveConfig(tailwindConfig)?.theme
  ?.screens as Record<string, string>;

type UserMenuProps = {
  microcopy: Record<string, string>;
  sections: UserProfileSectionFragment[];
  user?: User;
};

export const UserMenu = ({ microcopy, sections, user }: UserMenuProps) => {
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const toggleButtonRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowSize();
  const [selectedItem, setSelectedItem] = useState<UserMenuSelectedItem | null>(
    null
  );
  const formattedSections = useFormatCMSUserMenuItems({
    sections,
    eventHandlers: {
      setSelectedItem,
    },
  });

  function toggleAccountDropdown() {
    const desktopBreakpoint = parseInt(screensThemeConfig.lg, 10);
    setIsMobileMenu(windowWidth < desktopBreakpoint);
    setShowAccountDropdown(
      (prevShowAccountDropdown) => !prevShowAccountDropdown
    );
  }
  return (
    <>
      <div ref={toggleButtonRef}>
        <IconMenuOption
          name={microcopy['layout.open-user-menu'] ?? 'Open user profile menu'}
          testId={`${user ? 'signed-in' : 'signed-out'}-profile-menu-toggle`}
          onClick={toggleAccountDropdown}
        >
          {user ? (
            <Avatar
              userName={user.name}
              userProfilePicture={user.profilePicture ?? undefined}
              size={28}
            />
          ) : (
            <UserCircle
              size={20}
              className="fill-white"
              data-test="account-icon"
            />
          )}
        </IconMenuOption>
      </div>
      {/* Desktop */}
      {showAccountDropdown && !isMobileMenu && (
        <UserMenuDropdown
          isActive={showAccountDropdown}
          toggleButtonRef={toggleButtonRef}
          toggleDropdown={toggleAccountDropdown}
          user={user}
          sections={formattedSections}
          microcopy={microcopy}
        />
      )}
      {/* Mobile */}
      <UserMenuModal
        isActive={showAccountDropdown && isMobileMenu}
        toggleDropdown={toggleAccountDropdown}
        user={user}
        sections={formattedSections}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        microcopy={microcopy}
      />
    </>
  );
};
