import c from 'classnames';
import { ArrowRight } from 'phosphor-icons';

type Props = {
  value: string;
  href: string;
  highlight?: boolean;
  className?: string;
};

const NavigationLink = ({
  value,
  href,
  highlight = false,
  className = '',
}: Props) => {
  return (
    <li className={c('mb-3', className)}>
      <a
        href={href}
        className="body-small focus-outline flex items-center rounded text-white outline-offset-2 transition ease-in hover:translate-x-1 hover:text-blue"
      >
        {value}
        {highlight && <ArrowRight size={16} className="ml-1" />}
      </a>
    </li>
  );
};

export default NavigationLink;
