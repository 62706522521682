import { NavigationItem } from '../types';
import { DesktopMenuItem } from './DesktopMenuItem';

type DesktopMenuProps = {
  navigationItems: NavigationItem[];
};

export const DesktopMenu = ({ navigationItems }: DesktopMenuProps) => {
  const alignedLeftItems = navigationItems.filter(
    (item) => !item.isRightAligned
  );
  const alignedRightItems = navigationItems.filter(
    (item) => item.isRightAligned
  );

  return (
    <>
      <ul className="flex">
        {alignedLeftItems.map((navigationItem) => (
          <DesktopMenuItem
            key={navigationItem.mainLink.value}
            item={navigationItem}
          />
        ))}
        {alignedRightItems.map((navigationItem, index) => (
          <DesktopMenuItem
            key={navigationItem.mainLink.value}
            item={navigationItem}
            className={index === 0 ? 'ml-auto' : ''}
          />
        ))}
      </ul>
      <div
        id="active-indicator"
        className="pointer-events-none absolute bottom-0 left-0 h-0.5 w-0 bg-blue transition-all ease-out"
      />
    </>
  );
};
