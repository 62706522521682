import {
  FastActionsMenuItemDropdownMenuCollection,
  FastActionsMenuItemFragment,
} from '@graphql/generated-contentful/graphql';
import { DynamicIcon } from 'phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import { FocusOn } from 'react-focus-on';
import { Tooltip, TooltipArrow, useTooltip } from 'ui-components';
import { IconMenuOption } from '../Header/IconMenuOption';
import { FastActionMenuCart } from './FastActionMenuCart';
import { FastActionMenuDropdown } from './FastActionMenuDropdown';

type FastActionMenuItemProps = {
  item: FastActionsMenuItemFragment;
};

export const FastActionMenuItem = ({ item }: FastActionMenuItemProps) => {
  const {
    context,
    isOpen: tooltipIsOpen,
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    arrowRef,
    setIsOpen: setTooltipIsOpen,
  } = useTooltip({
    offset: 8,
    arrow: true,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const wrapperRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (showDropdown) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [showDropdown]);

  const hasDropdownItems =
    item.dropdownMenuCollection?.items &&
    item.dropdownMenuCollection?.items.length > 0;

  const handleToggleDropdown = () => {
    if (hasDropdownItems) {
      setShowDropdown(!showDropdown);
      setTooltipIsOpen(false);
    }
  };

  const handleDropdownBlur = () => {
    setShowDropdown(false);
    setTooltipIsOpen(false);
  };

  return (
    <FocusOn
      enabled={showDropdown}
      onEscapeKey={handleDropdownBlur}
      onClickOutside={handleDropdownBlur}
    >
      {item.topLevelLink?.url === '/account/cart' ? (
        <FastActionMenuCart
          item={item}
          forwardedRef={(el: any) => {
            refs.setReference(el);
            wrapperRef.current = el;
          }}
          referenceProps={getReferenceProps()}
        />
      ) : (
        <IconMenuOption
          name={item.topLevelLink?.value!}
          href={item.topLevelLink?.url!}
          ref={(el: any) => {
            refs.setReference(el);
            wrapperRef.current = el;
          }}
          {...getReferenceProps()}
          onClick={hasDropdownItems ? handleToggleDropdown : undefined}
          className="relative hidden rounded-full md:flex"
          target={item.topLevelLink?.openInNewTab ? '_blank' : undefined}
        >
          <DynamicIcon
            iconName={item.topLevelLink?.icon!}
            width={20}
            height={20}
            className="fill-white"
          />
        </IconMenuOption>
      )}
      {showDropdown && (
        <FastActionMenuDropdown
          title={item.topLevelLink?.value!}
          dropdownMenu={
            item.dropdownMenuCollection as FastActionsMenuItemDropdownMenuCollection
          }
          onBlur={handleDropdownBlur}
        />
      )}
      {tooltipIsOpen && !showDropdown && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <TooltipArrow arrowRef={arrowRef} context={context} />
          <Tooltip label={item.topLevelLink?.value!} />
        </div>
      )}
    </FocusOn>
  );
};
