import { useButton } from '@ui-components/components/Button/useButton';

export const SkipToMainButton = ({
  label = 'Skip to main content',
}: {
  label?: string;
}) => {
  const { buttonClassNames } = useButton({
    hasArrow: false,
    style: 'primary',
    size: 'small',
    label,
    className: 'absolute -top-48 left-8 z-overlay text-blue focus:top-4',
    isDarkMode: false,
  });
  return (
    <button
      className={buttonClassNames}
      onClick={() => {
        const main = document.getElementById('main');
        if (main) {
          main.tabIndex = -1;
          main.focus();
        }
      }}
    >
      {label}
    </button>
  );
};
