import c from 'classnames';
import Image from 'next/image';
import { Link } from 'ui-components';
import { NavigationImage } from '../types';

type Props = {
  className?: string;
} & NavigationImage;

export const NavigationImageColumn = ({
  src,
  alt = '',
  description = '',
  link,
  className = '',
}: Props) => {
  return (
    <Link
      href={link.href}
      className={c(
        'focus-outline mt-10 h-fit rounded-md outline-offset-2',
        className
      )}
    >
      <figure>
        <Image
          src={src}
          alt={alt}
          width={282}
          height={188}
          // this component will never be visible on mobile (as of now)
          sizes="(max-width: 1024px) 0vw, 18.35rem"
          className="aspect-3/2 rounded-md object-cover"
        />
        {description && (
          <figcaption className="tiny-text mt-3 text-grey-500">
            {description}
          </figcaption>
        )}
      </figure>
    </Link>
  );
};
