import { failingFetcher } from '@lib/http/fetch';
import { Cart } from '@lib/types/api/Cart';
import { User } from '@lib/types/common/User';
import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useSWR from 'swr';

export const CartContext = createContext<{
  cart: Cart | null;
  setCart: (cart: Cart) => void;
  errorFetchingCart: any;
}>({
  cart: null,
  setCart: () => {},
  errorFetchingCart: null,
});

export const CartProvider = ({
  user,
  children,
}: PropsWithChildren<{ user?: User }>) => {
  const { data, error } = useSWR<Cart>(
    user ? `/api/carts/user` : `/api/carts/device`,
    failingFetcher
  );

  const [cartState, setCartState] = useState<Cart | null>(null);

  useEffect(() => {
    setCartState(data || null);
  }, [data]);

  const value = useMemo(() => {
    return {
      cart: cartState,
      setCart: setCartState,
      errorFetchingCart: error,
    };
  }, [cartState, setCartState, error]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
