import c from 'classnames';
import { BenefitsBarItem } from './BenefitsBarItem';

export const BenefitsBar = ({
  data,
}: {
  data: { text: string; icon: string }[];
}) => {
  return (
    <ul className="flex justify-center lg:justify-between">
      {data.map((benefit, index) => (
        <li
          key={benefit.text}
          className={c('block', {
            'hidden lg:block': index !== 0,
          })}
        >
          <BenefitsBarItem text={benefit.text} icon={benefit.icon} />
        </li>
      ))}
    </ul>
  );
};
