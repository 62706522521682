const { fontFamily } = require('tailwindcss/defaultTheme');

/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{js,ts,jsx,tsx}',
    '../../packages/ui-components/src/**/*.{js,ts,jsx,tsx}',
  ],
  presets: [require('../../tailwind.config.js')],
  theme: {
    fontFamily: {
      sans: ['var(--font-inter, Helvetica, sans-serif)', ...fontFamily.sans],
    },
  },
};
