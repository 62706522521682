import { DynamicIcon } from 'phosphor-icons/react';
import xss from 'xss';

type BenefitsBarItemProps = {
  text: string;
  icon: string;
};

export const BenefitsBarItem = ({ icon, text }: BenefitsBarItemProps) => {
  const sanitizedText = xss(text);
  return (
    <div className="flex items-center text-sm text-grey-900">
      <DynamicIcon
        aria-hidden="true"
        iconName={icon}
        className="mr-2 block h-5 w-5"
        weight="bold"
      />
      <span dangerouslySetInnerHTML={{ __html: sanitizedText }} />
    </div>
  );
};
