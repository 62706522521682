import { PropsWithChildren } from 'react';
import { getColumnElement } from '../common/helpers';
import { NavigationColumn } from '../types';

export type MegaMenuProps = {
  id: string;
  columns: NavigationColumn[];
};

export const MegaMenu = ({ id, columns }: PropsWithChildren<MegaMenuProps>) => {
  return (
    <div
      id={id}
      className="z-modal absolute left-0 top-full w-full border-t border-y-grey-800 bg-black pb-14 pt-6 text-white"
    >
      <div className="lg-container flex gap-x-4">
        {columns.map((column, index) =>
          getColumnElement(column, index, columns)
        )}
      </div>
    </div>
  );
};
