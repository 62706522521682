'use client';

import { useRouter } from '@contexts/Router/RouterContext';
import {
  AtomicLayoutSection,
  AtomicSearchBox,
  AtomicSearchBoxQuerySuggestions,
  AtomicSearchBoxRecentQueries,
} from '@coveo/atomic-react';
import { usePathname } from 'next/navigation';
import { Locale, getWithoutLocale } from 'utils';

const searchboxStyles = `
/* Searchbox */
atomic-search-box::part(wrapper) {
    background-color: var(--atomic-custom-grey-800);
    border: 1px solid var(--atomic-custom-grey-700);
    border-radius: var(--atomic-border-radius-md);
    height: var(--atomic-custom-spacing-10);
    margin: 1px;
}
atomic-search-box::part(wrapper):focus-within {
  --tw-ring-color: var(--atomic-primary-dark);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border: 1px solid transparent;
}

atomic-search-box::part(textarea),
atomic-search-box::part(textarea-expander)::after,
atomic-search-box::part(textarea-spacer) {
  color: var(--atomic-custom-white);
  font-weight: 500;
  padding: var(--atomic-custom-spacing-2);
  margin-top: -1px;
}

atomic-search-box::part(textarea)::placeholder {
  color: var(--atomic-custom-grey-600);
}

/* Buttons */
atomic-search-box::part(clear-button-wrapper) {
  padding: 0;
  display: flex;
  align-items: center;
  margin-right: var(--atomic-custom-spacing-2);
}

atomic-search-box::part(clear-button):focus-visible {
  border-radius: var(--atomic-border-radius);
  outline: 2px solid var(--atomic-primary-dark);
}

atomic-search-box::part(clear-icon) {
  color: var(--atomic-custom-grey-400);
}

atomic-search-box::part(submit-button-wrapper) {
  padding: 0;
  margin: 0;
}

atomic-search-box::part(submit-button) {
  width: var(--atomic-custom-spacing-10);
  height: 100%;
  border-radius: 0;
  border-top-right-radius: var(--atomic-border-radius-md);
  border-bottom-right-radius: var(--atomic-border-radius-md);
  background-color: var(--atomic-primary);
  display: block;
  /* search icon looks slightly off center, move it down slightly */
  padding-top: 4px;
}

atomic-search-box::part(submit-button):hover,
atomic-search-box::part(submit-button):focus-visible {
  background-color: var(--atomic-primary-dark);
}

atomic-search-box::part(submit-icon) {
  color: var(--atomic-on-primary);
}

/* Suggestions */
atomic-search-box::part(suggestions-wrapper) {
  top: calc(100% + 5px);
  background-color: var(--atomic-custom-grey-800);
  border: 1px solid var(--atomic-custom-grey-700);
  border-radius: var(--atomic-border-radius-md);
}

atomic-search-box::part(suggestion) {
    color: var(--atomic-custom-grey-300);
    border-radius: var(--atomic-border-radius-md);
}
atomic-search-box::part(suggestion):hover,
atomic-search-box::part(active-suggestion) {
    background-color: var(--atomic-custom-grey-900);
}
`;

export const AtomicSearch = () => {
  const { locale } = useRouter();
  const pathname = usePathname() || '/';
  const searchPageUrl =
    locale === Locale.ENGLISH ? '/search' : `/${locale}/search`;
  // Redirect to search page if not already there, if already on search page can save the time it takes to redirect to the page again
  const redirectionUrl =
    getWithoutLocale(pathname) === '/search' ? undefined : searchPageUrl;

  return (
    <AtomicLayoutSection section="search">
      <style>{searchboxStyles}</style>
      <AtomicSearchBox
        data-test="atomic-search-box"
        redirectionUrl={redirectionUrl}
        textarea
      >
        <AtomicSearchBoxQuerySuggestions maxWithoutQuery={0} />
        <AtomicSearchBoxRecentQueries />
      </AtomicSearchBox>
    </AtomicLayoutSection>
  );
};
