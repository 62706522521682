import { User } from '@lib/types/common/User';
import { getPageRootNode } from '@lib/util/getPageRootNode';
import c from 'classnames';
import { FullscreenModal, useAnimateVisibility } from 'ui-components';
import { MobileSubNavigation } from '../Navigation/Mobile/MobileSubNavigation';
import { HeaderMenuListItem } from '../Navigation/common/HeaderMenuListItem';
import { UserMenuContent } from './UserMenuContent';
import { UserMenuSection, UserMenuSelectedItem } from './types';

type UserMenuModalProps = {
  isActive: boolean;
  toggleDropdown: () => void;
  sections: UserMenuSection[];
  user?: User;
  selectedItem: UserMenuSelectedItem | null;
  setSelectedItem: (item: UserMenuSelectedItem | null) => void;
  microcopy: Record<string, string>;
};

export const UserMenuModal = ({
  isActive,
  toggleDropdown,
  sections,
  user,
  selectedItem,
  setSelectedItem,
  microcopy,
}: UserMenuModalProps) => {
  const pageRootNode = getPageRootNode();
  const mainMenuAnimationStates = useAnimateVisibility(!Boolean(selectedItem));
  const subMenuAnimationStates = useAnimateVisibility(Boolean(selectedItem));

  return (
    <FullscreenModal
      returnFocus
      isActive={isActive}
      closeLabel={microcopy['layout.close-menu'] || 'Close menu'}
      closeModal={toggleDropdown}
      portalDomNode={pageRootNode}
      className="lg:hidden"
    >
      <div className="main-grid h-full overflow-auto pb-[3.75rem]">
        <div className="col-span-4 col-start-1 md:col-span-8 md:col-start-3">
          {selectedItem ? (
            <MobileSubNavigation
              heading={selectedItem.heading}
              goBackMicrocopy={
                microcopy['layout.go-back-to-navigation'] ??
                'Go back to navigation'
              }
              goBack={() => setSelectedItem(null)}
              animationStates={subMenuAnimationStates}
            >
              <ul>
                {selectedItem.links.map((link) => (
                  <li key={link.value} className="pb-4 last:pb-0">
                    <HeaderMenuListItem
                      href={link.href}
                      openInNewTab={link.openInNewTab}
                      iconName={link.icon}
                      value={link.value}
                    />
                  </li>
                ))}
              </ul>
            </MobileSubNavigation>
          ) : (
            <UserMenuContent
              sections={sections}
              user={user}
              className={c('transition duration-300', {
                'opacity-1 ': mainMenuAnimationStates.isAnimatedIn,
                'opacity-0': mainMenuAnimationStates.isAnimating,
              })}
              switchOrganizationMicrocopy={
                microcopy['layout.switch-organization']
              }
            />
          )}
        </div>
      </div>
    </FullscreenModal>
  );
};
